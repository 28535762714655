
import styled from '@emotion/styled'
export default  styled.div`
      //  position:fixed;
      //  top:0;right:0;left:0;bottom:0;
      //  background-color:rgba(0,0,0,0.7);
       display:flex;
       justify-content:center;
       align-items:"center";
       img{
         margin:auto;
        width:200px;
        opacity:0.8 ;
       }
`