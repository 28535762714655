import React, { useEffect } from 'react'
import {
  BrowserRouter,
  Routes,
  Route,
  } from "react-router-dom";
import PlaylistPage from "../pages/playListPage";
import EditSongPage from "../pages/editSongPage";
import AddSongPage from "../pages/addSongPage";
import ReportPage from "../pages/reportPage";
import Loading from "../components/LoadingComponent";
import Layout from "../components/LayoutComponent";
import { useSelector,useDispatch} from 'react-redux';
import {toast} from "react-toastify";
import { selectError, selectIsLoading, selectMessage,getSongsFetch,setMessage,setError } from '../redux/appDataSlice';
export default function Routing() {  
      const isLoading =  useSelector(selectIsLoading);
      const dispatch = useDispatch();
      const error = useSelector(selectError);
      const message = useSelector(selectMessage);
      const routes = [
        {path:"/",component:<Layout><PlaylistPage /></Layout>},
        {path:"/addsong",component:<Layout><AddSongPage /></Layout>},
        {path:"/editsong/:id/",component:<Layout><EditSongPage /></Layout> },
        {path:"/report",component:<Layout><ReportPage /></Layout>}
      ];
    useEffect(()=>{
      dispatch(getSongsFetch());
    },[])
    useEffect(()=>{
     if(message!="")
     toast.success(message)
     dispatch(setMessage(""))
    },[message]);
    useEffect(()=>{
     if(error!="")
     toast.error(error);
     dispatch(setError(""))
    },[error]);
    return (
      <BrowserRouter>
          <Routes> 
              {routes.map((route,i)=><Route key={i} path={route.path} element={route.component} />)}
          </Routes>
          {isLoading && <Loading />}
     </BrowserRouter>
      )
    }
            